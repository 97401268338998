import React from "react"
import loadable from "@loadable/component"
import qs from "qs"
import tw, { styled, theme } from "twin.macro"

import Layout from "../layouts/base"
import SEO from "../components/seo"

const Cart = loadable(() => import("../components/cart"))
const Payment = loadable(() => import("../components/payment/index"))
const Loading = loadable(() => import("../components/loading"))

const Wrapper = styled.div`
	@media (max-width: ${theme`screens.md`}) {
		.container {
			${tw`max-w-full`}
		}
	}

	@media (min-width: ${theme`screens.md`}) {
		background-image: linear-gradient(90deg, ${theme`colors.white`} 50%, ${theme`colors.solitude`} 0);
	}
`

const paymentRef = React.createRef()

const CheckoutPage = ({ location }) => {
	const siteTitle = "Checkout"
	const searched = qs.parse(location.search.replace(/^\?/, ""))
	const [processing, setProcessing] = React.useState(false)
	const [showMessage, setshowMessage] = React.useState(true)

	const toggleIndicator = state => {
		setProcessing(state)
	}

	const maybeSigned = () => {
		if (searched?.event && "signing_complete" === searched.event && !!searched?.token) {
			toggleIndicator(true)
			setshowMessage(false)
			paymentRef.current.processAction("card", {
				signed: true,
				token: searched.token,
			})
		}
	}

	return (
		<Layout>
			<SEO title={siteTitle} />

			<main>
				<div tw="bg-asphalt text-granny py-12 md:py-16 xl:py-20">
					<div className="container">
						<h1>{siteTitle}</h1>
					</div>
				</div>

				<Wrapper>
					<div className="container" tw="md:(flex flex-wrap space-x-20) lg:space-x-40">
						<div tw="flex-1 p-12 md:px-0 md:py-16 xl:py-20 md:(sticky top-0 self-start)">
							<Cart />
						</div>

						<div tw="flex-1 p-12 md:px-0 md:py-16 xl:py-20 bg-solitude">
							<Payment onProcess={toggleIndicator} onLoad={maybeSigned} ref={paymentRef} />
						</div>
					</div>

					{processing && <Loading showMessage={showMessage} />}
				</Wrapper>
			</main>
		</Layout>
	)
}

export default CheckoutPage
